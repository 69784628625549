:root {
  --ligth-primaryColor-white: rgba(253, 255, 252, 1);
  --dark-primaryColor-dark: rgba(49, 47, 47, 1);
  --dark-sixColor-dark: rgba(49, 47, 47, 0.9);
  --dark-Color-dark: rgba(49, 47, 47, 0.7);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* .carousel__slide--focused .focusRing__1airF,.carousel__slide--focused .carousel__slide-focus-ring{
  border:none;
}
.carousel__slide--focused .focusRing__1airF.carousel__slide-focus-ring{
  background-color: red;
}
.carousel__slide--focused .carousel__inner-slide .carousel__slide-focus-ring{
  border:none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
section.error-wrapper {
  height: 70vh;
  text-align: center;
  position: relative;
  width: 100%;
}
section.walking {
  position: absolute;
  top: 30%;
  left: 0;
  background-image: url("./media/img/walking.png");
  width: 256px;
  height: 314px;
  /* transform: scale(0.5); */
  animation: walk 1s steps(8) infinite, move 4s linear infinite alternate,
    switch 8s linear infinite, sizer 4s linear infinite;
}
.carousel__dot-group > button {
  width: 16px;
  height: 16px;
  font-size: 8px;
  border-radius: 50%;
  margin: 2px;
  background: var(--ligth-primaryColor-white);
  color: black;
  border: 1px solid var(--dark-primaryColor-dark);
  transition: 0.3s all;
}
.carousel__dot-group > button:disabled {
  background: var(--dark-Color-dark);
  border: 1px solid black;
  color: var(--ligth-primaryColor-white);
}
.caruselDots > button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 2px;
  background: var(--ligth-primaryColor-white);
  border: 1px solid var(--dark-primaryColor-dark);
}
.caruselDots > button:disabled {
  background: var(--dark-primaryColor-dark);
}
.caruselDotsShops > button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 10px;
  background: var(--ligth-primaryColor-white);
  border: 1px solid var(--dark-primaryColor-dark);
}
.caruselTopDealsDots > button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 2px;
  background: var(--ligth-primaryColor-white);
  border: 1px solid var(--dark-primaryColor-dark);
}
.caruselDots > button:disabled,
.caruselDotsShops > button:disabled,
.caruselTopDealsDots > button:disabled {
  background: var(--dark-Color-dark);
  border: 1px solid black;
}

.tools {
  position: fixed;
  width: 100%;
  height: 0;
  bottom: 75px;
  right: 0;
  z-index: 89;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  background: var(--ligth-primaryColor-white);
  background-image: linear-gradient(
    315deg,
    "#ccc3c3" 0%,
    var(--ligth-primaryColor-white) 74%
  );
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.tools.dark {
  background: var(--dark-sixColor-dark);
  background-image: linear-gradient(
    315deg,
    "#0a0a0a " 0%,
    var(--dark-primaryColor-dark) 74%
  );
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.tools button:nth-of-type(5) {
  position: fixed;
  bottom: 20px;
  right: 10px;
}
.tools button:nth-of-type(4) {
  position: fixed;
  bottom: 20px;
  right: 50px;
}
.tools button:nth-of-type(3) {
  position: fixed;
  bottom: 20px;
  right: 90px;
}
.tools button:nth-of-type(2) {
  position: fixed;
  bottom: 20px;
  left: 100px;
}
.tools div:nth-of-type(1) {
  position: fixed;
  bottom: 28px;
  right: 125px;
}
.tools div:nth-of-type(1) div {
  position: absolute;
}
.tools div:nth-of-type(2) {
  position: fixed;
  bottom: 0px;
  left: 10px;
}
.tools button:nth-of-type(1) {
  position: fixed;
  bottom: 20px;
  left: 145px;
}
/* .tools button:nth-of-type(3) {
  position: fixed;
  bottom: 35px;
  left: 240px;
} */
.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}
.darkCalendar {
  background-color: rgba(49, 47, 47, 1) !important;
  z-index: 1000;
}
.darkCalendar .react-calendar__navigation button {
  color: white;
}

.lightCalendar {
  background-color: rgba(253, 255, 252, 0.6) !important;
}

.react-daterange-picker__inputGroup__leadingZero,
.react-date-picker__inputGroup__divider,
.react-daterange-picker__range-divider {
  color: black !important;
}
.react-daterange-picker__calendar {
  z-index: 1000 !important;
}
.svgItem {
  overflow: visible;
  stroke: #fff;
  shape-rendering: geometricPrecision;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
  height: 18px;
}
.svgItemForModal {
  overflow: visible;
  stroke: #fff;
  shape-rendering: geometricPrecision;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
  height: 150px;
}
.svgItemForModalValue {
  overflow: visible;
  stroke: #fff;
  shape-rendering: geometricPrecision;
  stroke-width: 10;
  stroke-linejoin: round;
  stroke-linecap: round;
  height: 150px;
}

.paginationLight {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  margin: 40px 0;
  user-select: none;
}
.paginationLight > li {
  width: 30px;
  cursor: pointer;
  text-align: center;
  transform: scale(1);
  transition: 0.3s all;
}

.selected {
  color: rgba(46, 196, 182, 1);
  transform: scale(1.2) !important;
  font-weight: bold;
  padding: 0 6px;
}
.next {
  width: 55px !important;
}
.previous {
  width: 55px !important;
}
.citySearchDark,
.citySearchLigth {
  width: 50%;
  z-index: 1000;
}
/* .grecaptcha-badge {
  visibility: hidden;
}  */
@media (max-width: 1199.98px) {
  /* .tools button:nth-of-type(5) {
    right: 10px;
  }
  .tools button:nth-of-type(4) {
    right: 55px;
  }
  .tools button:nth-of-type(3) {
    right: 100px;
  } */
  .tools div:nth-of-type(1) {
    right: 115px;
  }
}
@media (max-width: 991.98px) {
  .tools {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 33;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    /* margin-top: 30px; */
  }
  .tools button:nth-of-type(5),
  .tools button:nth-of-type(4),
  .tools button:nth-of-type(3),
  .tools button:nth-of-type(2),
  .tools div:nth-of-type(1),
  /* .tools div:nth-of-type(1) div, */
  .tools div:nth-of-type(2),
  .tools button:nth-of-type(1) {
    position: static;
    /* z-index: 33; */
  }

  .tools div:nth-of-type(1) {
    position: relative;
    bottom: unset;
    right: unset;
    /* z-index: 90; */
  }
}
@media (max-width: 823px) and (orientation: landscape) {
  section.error-wrapper {
    height: 90vh;
  }
}
@media (max-width: 768px) {
  .tools {
    justify-content: space-around;
    flex-wrap: nowrap;
    height: 65px;
  }
  .citySearchDark,
  .citySearchLigth {
    width: 95%;
  }
  /* .tools button:last-of-type {
    display: none;
  } */
}
@keyframes walk {
  100% {
    background-position: -2048px;
  }
}

@keyframes move {
  100% {
    left: calc(100% - 256px);
  }
}

@keyframes switch {
  0% {
    transform: rotateY(0);
  }

  50% {
    transform: rotateY(0);
  }

  50.01% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
