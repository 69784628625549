/* -----------nav-burger------------------ */

nav.burger-nav {
  position: fixed;
  display: block;
  z-index: 2;
  overflow: auto;
}
.burger-nav ul {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: 0.5s;
  list-style-type: none;
  overflow: hidden;
}
.burger-nav ul li {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.burger-nav ul li a {
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid rgb(45, 46, 45);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: rgb(45, 46, 45);
  cursor: pointer;
}

/* --------------burger- menu- active ----------*/
.burger-nav.canOverflow {
  z-index: -1;
}
.burger-nav.active {
  display: block;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
}
.burger-nav.active ul {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 10vh;
  height: 100vh;
  background-color: rgb(203, 208, 213);
  z-index: 100;
  list-style-type: none;
  overflow: hidden;
}
.burger-nav.active ul li {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.burger-nav.active ul li a {
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid rgb(45, 46, 45);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: rgb(45, 46, 45);
  cursor: pointer;
}

.burger-nav.active ul a:hover {
  background-color: #fff;
}
/* ----------------------burger-icon -----------------*/
/* .burger {
      width: 70px;
      height: 70px;
      position:relative;
      cursor: pointer;
      transition: 0.3s;
      z-index: 3;
    }
    .burger.active{
        z-index: 3;
    }
  
    .first-span,
    .second-span,
    .third-span {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 25px;
      height: 4px;
      border-radius: 3px;
      background-color: rgb(45, 46, 45);
  
      transform-origin: right;
      transform: rotate(0);
      transition: 0.3s;
    }
    .second-span {
      top: 30px;
      right: 15px;
    }
    .third-span {
      top: 40px;
    }
    .burger:hover .first-span,
    .burger:hover .third-span {
      width: 25px;
    }
    .burger:hover .second-span {
      width: 25px;
      right: 20px;
    }
  
   .burger.active .first-span {
      width: 28.25px;
      transform: rotate(-45deg);
    }
    .burger.active .second-span {
      width: 0;
    }
    .burger.active .third-span {
      width: 28.25px;
      transform: rotate(45deg);
    }
  

   */
